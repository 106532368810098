import './newText2.scss';
import React from 'react';
import {Text} from "@mantine/core";

interface Props {
    text?: string;
    className?: string;
    children?: React.ReactNode;
}

export function NewText2(props: Props): JSX.Element {
    return <Text className={"new-text2 " + props.className}>{props.children ?? props.text}</Text>
}