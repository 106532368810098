import {getRequest, postRequest} from "../requests/HelperRequest";
import {PaymentsHistoriesModel, BallsResponse, BallsSubRequest} from "./models/PaymentsHistoriesModel";
import AuthStore from "../auth/store/AuthStore";
import {CheckPromoCodeModel, PackModel} from "./models/PackModel";
import {PromoCodeListDto} from "../promoCodes/models/PromoCodeListDto";

export class BallsRepository {
    loaded = true;
    promoCodeLoaded = true;

    request(dto: BallsSubRequest) {
        this.loaded = false;
        postRequest<BallsResponse, BallsSubRequest>('api/Balls/CreateSubs', dto)
            .then((response) => {
                AuthStore.redirect(response.Model!.link);
            })
            .catch((error) => {
                // alert('Возникла ошибка напиши в подержку!')
                console.log(error);
            }).finally(() => {
            this.loaded = true;
        });
    }

    requestSubs(promoCode: string | undefined) {
        this.loaded = false;
        console.log('requestSubs GetSubs: ', promoCode);
        return postRequest<PackModel[], CheckPromoCodeModel>("api/Balls/GetSubs", {promoCode: promoCode ?? ""})
            .catch((error) => {
                // alert('Возникла ошибка напиши в подержку!')
                console.log(error);
            }).finally(() => {
                this.loaded = true;
            });
    }

    checkPromoCode(promoCode: string | undefined) {
        this.promoCodeLoaded = false;
        return postRequest<PromoCodeListDto, CheckPromoCodeModel>("api/Balls/CheckPromoCode", {promoCode: promoCode ?? ""})
            .catch((error) => {
                // alert('Возникла ошибка напиши в подержку!')
                console.log(error);
            }).finally(() => {
                this.promoCodeLoaded = true;
            });
    }

    requestBalls() {
        // setBalls(undefined);
       return getRequest<PaymentsHistoriesModel[]>("api/Balls")
            .then(x => {
                if (!!x.Model) {
                    // setBalls(x.Model)
                } else if (!!x.ErrorM) {
                    alert(x.Error)
                }
            });
    }
}