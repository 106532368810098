import React from 'react';
import './viewsPage.scss';
import {NewButton} from "../../components/button/NewButton";
import {NewInput} from "../../components/inputs/NewInput";
import {NewText} from "../../components/text/NewText";
import {NewText2} from "../../components/text/NewText2";
import {Block} from "../../components/block/Block";
import {TitleText} from "../../components/titleText/TitleText";

export const ViewsPage = () => {

    return (
        <div className="views">
            <div>
                <NewButton/>
                <NewButton disabled={true}/>
            </div>
            <div>
                <NewInput />
                <NewInput error={"text error"} />
            </div>
            <div>
                <NewText />
                <NewText2 text={"another-text"} />
            </div>
            <div>
                <Block />
                <Block sealed={true}/>
            </div>
            <div>
                <TitleText text={"Войти"} />
            </div>
        </div>
    );
};
