import React, {useEffect, useState} from "react";
import './paymentsComponent.scss'
import {TitleText} from "../../components/titleText/TitleText";
import {BallsComponent} from "../../components/balls/BallsComponent";
import {DividerComponent} from "../../components/divider/DividerComponent";
import {NewButton} from "../../components/button/NewButton";
import {NewInput} from "../../components/inputs/NewInput";
import {PackComponent} from "../../components/pack/PackComponent";
import {PackModel} from "../../balls/models/PackModel";
import {BallsRepository} from "../../balls/BallsRepository";
import {useInputState} from "@mantine/hooks";
import {observer} from "mobx-react";

export const PaymentsComponent = observer(() => {
    let [packs, setPacks] = useState<PackModel[] | undefined>(undefined);
    let [promoCode, setPromoCode] = useInputState<string | undefined>(undefined);
    let [hasPromoCode, setHasPromoCode] = useState<boolean>(false);
    let repository = new BallsRepository();

    const requestSub = () => {
        setPacks(undefined);
        repository.requestSubs(hasPromoCode ? promoCode : "")
            .then(x => {
                if (x) {
                    if (!!x.Model) {
                        setPacks(x.Model)
                    } else if (!!x.ErrorM) {
                        alert(x.Error)
                    }
                }
            })
    }

    useEffect(() => {
        requestSub()
    }, [])

    useEffect(() => {
        requestSub()
    }, [hasPromoCode])

    const buyBalls = (id: string, price: number) => {
        repository.request({amount: price, promoCode: promoCode ?? "", id: id});
    }

    const checkPromoCode = () => {
        repository.checkPromoCode(promoCode)
            .then(x => {
                setHasPromoCode(true);
            }).catch(() => {
            alert('Промокод не найден')
        });
    }

    const clearPromocode = () => {
        setHasPromoCode(false);
    }

    return <div className="payments-container">
        <div className={"title-wrapper"}>
            <TitleText text={"Баланс"} className={"title-component"}/>
            <BallsComponent className={"balls_text"}/>
        </div>
        <DividerComponent/>
        <div className={"promocode-wrapper"}>
            <TitleText text={"Промокод"} className={"title-component"}/>
            <NewInput className="marginTop16"
                      onChange={setPromoCode}
                      value={promoCode}
                      placeholder={"Введите промокод"}
            />
            {
                hasPromoCode &&
                <NewButton className="marginBottom16 marginTop16"
                           disabled={promoCode?.length === 0}
                           loading={false}// todo Это не работает!
                           text={"Отменить промокод"}
                           onClick={clearPromocode}
                />
            }
            {
                !hasPromoCode &&
                <NewButton className="marginBottom16 marginTop16"
                           disabled={promoCode?.length === 0}
                           loading={false}// todo Это не работает!
                           text={"Подтвердить"}
                           onClick={checkPromoCode}
                />
            }

        </div>
        <TitleText text={"Пакеты"} className={"title-component marginBottom16"}/>
        <div className={"payments-packs-wrapper"}>
            {
                packs &&
                packs.map(x =>
                    <PackComponent key={x.id} pack={x} buy={buyBalls}/>)
            }
        </div>
    </div>
})