import {observer} from "mobx-react";
import React from "react";
import './ballsComponent.scss';
import AuthStore from "../../auth/store/AuthStore";

interface Props {
    className?: string;
}

export const BallsComponent = observer((props: Props) => {
    return <div className={"balls_component " + props.className}>{AuthStore.userModel?.balls} Б</div>;
});