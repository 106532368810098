import React, {useState} from 'react';
import './authComponent.scss'
import {ConfirmEmailComponent} from "./components/ConfirmEmailComponent";
import {NewAuthorizationComponent} from "./components/NewAuthorizationComponent";
import {NewRegistrationComponent} from "./components/NewRegistrationComponent";
import {NewForgotPasswordComponent} from "./components/NewForgotPasswordComponent";

export enum AuthType {
    'auth',
    'registration',
    'forgotPassword',
    'confirmEmail' = 3
}

export const AuthComponent = () => {
    const [type, setType]
        = useState(AuthType.auth);
    const [email, setEmail]
        = useState("");

    const clickForgotPassword = () => {
        setType(AuthType.forgotPassword);
    }
    const clickAuth = () => {
        setType(AuthType.auth);
    }
    const clickRegistration = () => {
        setType(AuthType.registration);
    }
    const confirmEmail = (email: string) => {
        setEmail(email);
        setType(AuthType.confirmEmail);
    }

    return (
        <div className="auth-component">
            {
                type === AuthType.auth &&
                <NewAuthorizationComponent clickForgotPassword={clickForgotPassword}
                                        clickRegistration={clickRegistration} />
            }
            {
                type === AuthType.forgotPassword &&
                <NewForgotPasswordComponent clickAuth={clickAuth} confirmEmail={confirmEmail} />
            }
            {
                type === AuthType.registration &&
                <NewRegistrationComponent clickAuth={clickAuth} confirmEmail={confirmEmail}/>
            }
            {
                type === AuthType.confirmEmail &&
                <ConfirmEmailComponent clickAuth={clickAuth} email={email}/>
            }
        </div>
    );
};
