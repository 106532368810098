import React from 'react';
import './authPage.scss'
import {AuthComponent} from "../auth/AuthComponent";

export const AuthPage = () => {

    return (
        <div className="auth-page">
            <AuthComponent />
        </div>
    );
};
