import './textArea.scss';
import React from 'react';
import {Textarea} from "@mantine/core";
import {TextInputProps} from "@mantine/core/lib/components/TextInput/TextInput";
import {PasswordInputProps} from "@mantine/core/lib/components/PasswordInput/PasswordInput";


interface Props {
    className?: string;
    placeholder?: string;
    label?: string;
    error?: string
    inputProps?: TextInputProps
    inputPasswordProps?: PasswordInputProps
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    value?: string;
}

export function NewTextArea(props: Props): JSX.Element {
    return <Textarea
        classNames={{
            input: "text-area"
        }}
        style={{width: '100%'}}
        placeholder={props.placeholder}
        value={props.value}
        error={props.error}
        onChange={props.onChange}
        rows={10}
    />
}