import React from "react";
import './settingsPaymentsPage.scss'
import {CustomPaymentImage, CustomUserImage} from "../../helper/CustomUserImage";
import {NewText} from "../../components/text/NewText";
import {SettingsComponent} from "./SettingsComponent";
import {PaymentsComponent} from "./PaymentsComponent";
import {HistoryComponent} from "./HistoryComponent";
import {Link} from "react-router-dom";

export enum TypeSettingsPayments {
    settings = 0,
    payments = 1
}

interface Props {
    type: TypeSettingsPayments;
}

export const SettingsPaymentsPage = (props: Props) => {
    return <div className="settings-payments-page">
        <div className="menu-selector-component">
            <div className="menu-selector-wrapper">
                <CustomUserImage className="user-image"/>
                <Link to={"/settings"} style={{textDecoration: "none"}}>
                    <NewText className={"selector-title " + (props.type === TypeSettingsPayments.settings ? "active" : "")}>
                        Настройки профиля
                    </NewText>
                </Link>
            </div>
            <div className="menu-selector-wrapper">
                <CustomPaymentImage className="user-image"/>
                <Link to={"/payments"} style={{textDecoration: "none"}}>
                    <NewText
                        className={"selector-title " + (props.type === TypeSettingsPayments.payments ? "active" : "")}>
                        Оплата
                    </NewText>
                </Link>
            </div>
        </div>
        {
            props.type === TypeSettingsPayments.settings &&
            <div className="main-content">
                <SettingsComponent/>
            </div>
        }
        {
            props.type === TypeSettingsPayments.payments &&
            <>
                <div className="main-content payments-component">
                    <PaymentsComponent/>
                </div>
                <div className="main-content history-component">
                    <HistoryComponent/>
                </div>
            </>
        }
    </div>
}