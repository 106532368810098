import {SettingsStore} from "../settingsPage/SettingsStore";
import React, {useEffect} from "react";
import './settingsComponent.scss'
import {TitleText} from "../../components/titleText/TitleText";
import {InputType, NewInput} from "../../components/inputs/NewInput";
import {NewButton} from "../../components/button/NewButton";

export const SettingsComponent = () => {
    let store = new SettingsStore();
    useEffect(() => {
        store.init();
    }, [])// todo не забыть про ошибку!!
    return <div className="settings-container">
        <form onSubmit={store.formChangeName.onSubmit((values, event) =>
            store.updateName(values.firstName, values.surName))}>
            <TitleText text={"Личные данные"} className="title-component"/>
            <NewInput className="marginTop16"
                      placeholder={"Введите Имя"}
                      inputProps={store.formChangeName.getInputProps('firstName')}/>
            <NewInput className="marginTop16"
                      placeholder={"Введите Фамилию"}
                      inputProps={store.formChangeName.getInputProps('surName')}/>
            <NewButton className="marginBottom16 marginTop16"
                       loading={store.formChangeName.values.loading}
                       text={"Сохранить"}
            />
        </form>
        <form onSubmit={store.formChangeEmail.onSubmit((values, event) =>
            store.updateEmail(values.newEmail))}>
            <NewInput className="marginTop16"
                      placeholder={"Новый E-mail"}
                      inputProps={store.formChangeEmail.getInputProps('newEmail')}
            />
            <NewButton className="marginBottom16 marginTop16"
                       loading={store.formChangeEmail.values.loading}
                       text={"Сменить E-mail"}
            />
        </form>
        <form onSubmit={store.formChangePassword.onSubmit((values, event) =>
            store.updatePassword(values.oldPassword, values.newPassword))}>
            <TitleText text={"Пароль"} className="title-component"/>
            <NewInput type={InputType.password}
                      className="marginTop16"
                      placeholder={"Старый пароль"}
                      inputPasswordProps={store.formChangePassword.getInputProps('oldPassword')}
            />
            <NewInput type={InputType.password}
                      className="marginTop16"
                      placeholder={"Новый пароль"}
                      inputPasswordProps={store.formChangePassword.getInputProps('newPassword')}
            />
            <NewButton className="marginBottom16 marginTop16"
                       disabled={store.formChangePassword.values.newPassword.length <= 8 ||
                           store.formChangePassword.values.oldPassword.length <= 8}
                       loading={store.formChangePassword.values.loading}
                       text={"Изменить пароль"}
            />
        </form>
        {
            store.formEmailConfirmed.values.confirmedEmail === false &&
            <form onSubmit={store.formChangeEmail.onSubmit((values, event) =>
                store.confirmEmail())}>
                <TitleText text={"Подтвердить E-mail"} className="title-component"/>
                <NewButton className="marginBottom16 marginTop16"
                           loading={store.formEmailConfirmed.values.loading}
                           text={"Подтвердить"}
                />
            </form>
        }
    </div>
}