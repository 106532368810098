import './packComponent.scss';
import {TitleText} from "../titleText/TitleText";
import {NewButton} from "../button/NewButton";
import {PackModel} from "../../balls/models/PackModel";


interface Props {
    pack: PackModel;
    buy: (id: string, price: number) => void;
}

export function PackComponent(props: Props) {
    const buy = () => {
        props.buy(props.pack.id, props.pack.amount)
    }
    return <div className={"pack-component"}>
        <TitleText text={props.pack.name} className="pack-title"/>
        <TitleText text={props.pack.balls + " баллов"}
                   className={"pack-balls " + (props.pack.currentBalls === null ? "" : "cross-out")}/>
        <div className={"marginBottom16"}/>
        {
            props.pack.currentBalls &&
            <TitleText text={props.pack.currentBalls + " баллов"} className="pack-balls"/>
        }
        <NewButton text={props.pack.amount + " ₽"} className={"pack-button"} onClick={buy}/>
    </div>
}