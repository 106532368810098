import React from 'react';
import './faqPage.scss';

export const FaqPage = () => {

    return (
        <div className="faq-page">
            <div className="faq-title-main">FAQ</div>
            <div className="marginTop32"></div>
            <div>
                <div className="faq-title">Что такое похожие каналы?</div>
                <div className="marginTop16"></div>
                <div className="faq-description">
                    Похожие каналы в Telegram — это инструмент, позволяющий пользователям находить каналы с наибольшей
                    пересекающейся аудиторией с исходным каналом. Этот список формируется на основе анализа количества
                    общих
                    подписчиков между каналами. Чем выше канал в списке похожих, тем больше подписчиков из исходного
                    канала
                    присутствует в этом канале.
                    <br/>
                    <br/>
                    Наш сервис предоставляет возможность поиска каналов, в которых больше всего подписчиков из
                    указанного
                    исходного канала. Например, если вы укажете десять каналов, связанных с маркетингом, система сможет
                    найти несколько сотен каналов, также посвященных маркетингу, так как их аудитория будет пересекаться
                    с
                    исходными каналами.
                    <br/>
                    <br/>
                    Важно отметить, что не у всех каналов есть похожие каналы. В таких случаях, анализ будет невозможен.
                    Наш
                    сервис использует только внутренние инструменты Telegram и предоставляет те похожие каналы, которые
                    сам
                    Telegram может предложить.
                </div>
            </div>
            <div className="marginTop32"></div>
            <div>
                <div className="faq-title">Стоимость парсинга</div>
                <div className="marginTop16"></div>
                <div className="faq-description">
                    Стоимость поиска похожих каналов у одного канала составляет 1 балл.
                    <br/>
                    <br/>
                    Стоимость анализа одного канала составляет 0.1 балл
                </div>
            </div>

            <div className="marginTop32"></div>
            <div>
                <div className="faq-title">Что такое анализ каналов?</div>
                <div className="marginTop16"></div>
                <div className="faq-description">
                    Анализ каналов - это сбор информации по каждому каналу, который вы предоставляете.
                    <br/>
                    <br/>
                    Сервис предоставит следующую информацию: Имя канала, описание канала, количество подписчиков,
                    средний
                    охват постов за длительное время, средний охват рекламных постов.
                    <br/>
                    <br/>
                    С помощью этого анализа вы сможете быстро отобрать каналы из большого списка каналов. Например,
                    найти
                    те, у которых охват не менее 1000 подписчиков, а в описании указан контакт администратора.
                </div>
            </div>
        </div>
    );
};
