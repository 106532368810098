import React from 'react';
import {Link, useLocation} from "react-router-dom";
import AuthStore from "../auth/store/AuthStore";
import {Menu, Group, Button} from '@mantine/core';
import './layout.scss';
import {observer} from "mobx-react";
import {CustomUserImage} from "../helper/CustomUserImage";
import {BallsComponent} from "../components/balls/BallsComponent";
import FooterComponent from "./FooterComponent";

export const Layout = observer(({children}: { children: React.ReactNode }) => {
    const location = useLocation();

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         if (AuthStore.isAuth) {
    //             AuthStore.updateAccount();
    //         }
    //     }, 4000);
    //     return () => clearInterval(timer);
    // }, []);
    if (!AuthStore.isAuth)
        return <></>

    return (
        <div className="layout-wrapper">
            <div className="layout_page">
                <Group className="menu_group">
                    <Link to="/"
                          className={`menu_button layout-title-wrapper ${location.pathname === '/' ? 'active_button' : ''}`}>
                        <img className={"logo"} src="/logo.png" alt="logo"/>
                        <div className="layout-title marginLeft16">Telepars</div>
                    </Link>
                </Group>
                <Group className="right_group">
                    <div className="menu_list">
                        <Link to="https://telepars.ru/blog"
                              className={`menu_button ${location.pathname === '/blog' ? 'active_button' : ''}`}>Блог</Link>
                        <Link to="/faq"
                              className={`menu_button ${location.pathname === '/faq' ? 'active_button' : ''}`}>FAQ</Link>
                    </div>
                    <Menu position="bottom-end" width={209} transitionProps={{ transition: 'rotate-right', duration: 150 }}>
                        <Menu.Target>
                            <Button className={"avatar_button"}>
                                <CustomUserImage className="user-image"/>
                            </Button>
                        </Menu.Target>

                        <Menu.Dropdown classNames={{dropdown: "menu_dropdown"}}>
                            <Menu.Item classNames={{item: "menu_dropdown_item"}} component={Link} to={"/"}>
                                {AuthStore.userModel?.fullname}
                            </Menu.Item>
                            <Menu.Item rightSection={<BallsComponent />} classNames={{item: "menu_dropdown_item"}}
                                       component={Link} to={"/payments"}>
                                Оплата
                            </Menu.Item>
                            <Menu.Item classNames={{item: "menu_dropdown_item"}} component={Link} to={"/settings"}>
                                Настройка
                            </Menu.Item>
                            {/*<Menu.Item classNames={{item: "menu_dropdown_item"}} component={Link} to={"/payments"}>*/}
                            {/*    Оплата*/}
                            {/*</Menu.Item>*/}
                            {
                                AuthStore.admin &&
                                <Menu.Item classNames={{item: "menu_dropdown_item"}} component={Link} to={"/promocodes"}>
                                    Промокоды
                                </Menu.Item>
                            }
                            <Menu.Item
                                classNames={{item: "menu_dropdown_item menu_dropdown_exit"}}
                                onClick={AuthStore.signOut}>
                                Выйти
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </div>
            <div className="layout-content">
                {children}
            </div>
            <FooterComponent />
        </div>
    );
})
