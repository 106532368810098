import React, {useEffect, useState} from "react";
import './historyComponent.scss';
import {TitleText} from "../../components/titleText/TitleText";
import {DividerComponent} from "../../components/divider/DividerComponent";
import {PaymentHistoryComponent} from "../../components/paymentHistoryComponent/PaymentHistoryComponent";
import {PaymentsHistoriesModel} from "../../balls/models/PaymentsHistoriesModel";
import {getRequest} from "../../requests/HelperRequest";

export const HistoryComponent = () => {
    let [paymentHistories, setPaymentHistory] = useState<PaymentsHistoriesModel[] | undefined>(undefined);
    const request = () => {
        setPaymentHistory(undefined);
        getRequest<PaymentsHistoriesModel[]>("api/Balls")
            .then(x => {
                if (!!x.Model) {
                    setPaymentHistory(x.Model)
                } else if (!!x.ErrorM) {
                    alert(x.Error)
                }
            });
    }

    useEffect(() => {
        request()
    }, [])

    return <div className="history-container">
        <div className={"title-wrapper"}>
            <TitleText text={"История расходов"} className={"title-component"}/>
        </div>
        <DividerComponent/>
        <div className="histories-wrapper">
            {
                paymentHistories &&
                paymentHistories.map(x =>
                    <PaymentHistoryComponent key={x.id} model={x}/>)
            }
        </div>
    </div>
}