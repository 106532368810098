import {LoadingModel} from "../requests/LoadingModel";
import {TaskLoadingCreateDto, TaskLoadingListDto} from "./models/TaskLoadingDto";
import {getRequest, postRequestRequest} from "../requests/HelperRequest";
import {makeAutoObservable} from "mobx";

class TaskLoadingStore {
    taskLoadingList = LoadingModel.createLoaded<TaskLoadingListDto[]>();

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
    }

    taskLoadingListSet(model: LoadingModel<TaskLoadingListDto[]>) {
        this.taskLoadingList = model;
    }

    request = (lastId: number | undefined = undefined): void => {
        getRequest<TaskLoadingListDto[]>('api/TaskLoading/List/')
            .then((response) => {
                this.taskLoadingListSet(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    createRequest = (modelRequest: TaskLoadingCreateDto) => {
        return postRequestRequest<TaskLoadingCreateDto>("api/TaskLoading/Create", modelRequest)
            .then(x => {
                this.request();
                return x;
            });
    }
}

export default new TaskLoadingStore();