import {
    AuthForgotPasswordModel,
    AuthGetUserModel,
    AuthModel,
    AuthRegistrationModel,
    AuthSignInModel
} from "../model/AuthModel";
import {getRequest, postRequest, postRequestRequest} from "../../requests/HelperRequest";
import {makeAutoObservable} from "mobx";

class AuthStore {
    isErrorWas = false;
    isLoading: boolean = false;
    userModel: AuthGetUserModel | null = null;

    private save(model: AuthSignInModel) {
        if (model.successful) {
            localStorage.setItem('jwt', model.jwt ?? "");
            localStorage.setItem('username', model.username ?? "");
            window.location.reload();
            return;
        }
    }
    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
    }

    private saveUser(model: AuthGetUserModel) {
        if (model === null){
            this.signOut();
        }
        this.isErrorWas = false;
        this.userModel = model;
        localStorage.setItem('fullname', model.fullname);
    }

    private loadingFalse() {
        this.isLoading = false;
    }

    private loadingTrue() {
        this.isLoading = false;
    }

    get isAuth(): boolean {
        return localStorage.getItem('jwt') !== null;
    }

    get balls(): number {
        if (!!this.userModel?.balls) {
            return this.userModel.balls;
        }
        return 0;
    }

    get admin(): boolean {
        if (!!this.userModel?.admin) {
            return this.userModel.admin;
        }
        return false;
    }

    updateAccount() {
        if (!this.isAuth)
            return;
        return getRequest<AuthGetUserModel>('api/Auth/GetUser')
            .then(x => this.saveUser(x.Model!))
            .catch(_ => {
                this.catchErrorUpdateAccount()
            });
    }
    catchErrorUpdateAccount(){
        if (!this.isErrorWas)
            alert('На сайте идут технические работы. В ближайшее время они закончатся!');
        this.isErrorWas = true;
    }

    authorization(model: AuthModel) {
        this.loadingTrue();
        return postRequest<AuthSignInModel, AuthModel>('api/Auth/SignIn', model)
            .then(x => {
                this.loadingFalse();
                this.save(x.Model!);
                // this.updateAccount();
                return x.Model!;
            });
    }

    forgotPassword(model: AuthForgotPasswordModel) {
        this.loadingTrue();
        return postRequestRequest<AuthForgotPasswordModel>('api/Auth/ForgotPassword', model)
            .then(x => {
                this.loadingFalse()
            });
    }

    registration(model: AuthRegistrationModel) {
        this.loadingTrue();
        return postRequest<AuthSignInModel, AuthRegistrationModel>('api/Auth/Registration', model)
            .then(x => {
                this.loadingFalse();
                // this.save(x.Model!);
                return x.Model!
            });
    }

    signOut() {
        // this.loadingTrue();
        postRequestRequest('api/auth/SignOut', {})
            .then(x => {
                // this.loadingFalse();
                localStorage.removeItem('jwt');
                localStorage.clear();
                window.location.reload();
            })
    }

    redirect(link: string) {
        window.location = link;
    }
}

export default new AuthStore();