import {Card} from "@mantine/core";
import AuthStore from "../store/AuthStore";
import React from "react";
import {isEmail, useForm} from "@mantine/form";
import {TitleText} from "../../components/titleText/TitleText";
import {NewInput} from "../../components/inputs/NewInput";
import {LinkText} from "../../components/text/LinkText";
import {NewButton} from "../../components/button/NewButton";

interface Props {
    clickAuth: () => void;
    confirmEmail: (email: string) => void;
}

export const NewForgotPasswordComponent = (props: Props) => {
    const formForgot = useForm({
        mode: 'controlled',
        initialValues: {
            email: '',
        },
        validate: {
            email: isEmail('Не проходит валидация по e-mail'),
        },
    });

    const forgotEmailClick = (email: string) => {
        AuthStore.forgotPassword({
            email: email
        }).then(x =>{
            props.confirmEmail(email);
        });
    };

    return <Card className="auth-card" shadow="sm" padding="lg" radius="md" withBorder>
        <form onSubmit={formForgot.onSubmit((values, event) =>
            forgotEmailClick(values.email))}>
            <TitleText className="text-block" text={"Восстановить пароль"}/>
            <NewInput className="marginTop32 marginBottom32"
                      placeholder={"Логин или e-mail"}
                      inputProps={formForgot.getInputProps('email')}/>
            <NewButton className="marginBottom32"
                       loading={AuthStore.isLoading}
                       text={"Восстановить"}
            />
            <LinkText className="marginTop16 marginBottom16"
                      onClick={props.clickAuth}>Помните пароль?</LinkText>
        </form>
    </Card>
}