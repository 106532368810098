import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {TableColumnDto, TaskLoadingDto, TypeTask} from "./models/TaskLoadingDto";
import TaskLoadingStore from "./TaskLoadingStore";
import {getRequest, getRequestResponse} from "../requests/HelperRequest";
import {TaskLoadingRowComponent} from "./TaskLoadingRowComponent";
import './taskLoadingTableComponent.scss';
import './combinedComponent.scss';
import {TitleText} from "../components/titleText/TitleText";
import {NewText2} from "../components/text/NewText2";
import {DividerComponent} from "../components/divider/DividerComponent";
import TaskLoadingModalTable from "./components/TaskLoadingModalTable";

interface Props {
    addChannels: (channels: string[]) => void;
}

export const TaskLoadingTableComponent = observer((props: Props) => {
    let [modal, setModal] = useState(false);
    let [columns, setColumns] = useState<TableColumnDto[]>();
    let [taskLoadingDto, setTaskLoadingDto] = useState<TaskLoadingDto>();
    let [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        TaskLoadingStore.request();
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
            TaskLoadingStore.request();
        }, 3000);
        return () => clearInterval(timer);
    }, []);

    const close = () => {
        setModal(false)
    }
    const open = (id: bigint) => {
        setModal(true)
        setColumns([]);
        loadingDescriptionModel(id);
    }
    const repeatTask = (id: bigint) => {
        getRequestResponse('api/TaskLoading/' + id + '/repeatTask')
            .then(x => {
                TaskLoadingStore.request();
            })
    }

    const loadingDescriptionModel = (id: bigint) => {
        setLoading(true);
        getRequest<TaskLoadingDto>('api/TaskLoading/Get?id=' + id)
            .then(x => {
                setTaskLoadingDto(x.Model!)
                setLoading(false);
                if (x.Model?.resultJson !== '{}' && x.Model?.resultJson !== '[]') {
                    setColumns(JSON.parse(x.Model?.resultJson ?? "[]"));
                }
            });
    }

    const addArrayChannels = (v: ({ index: number[] })) => {
        if (taskLoadingDto!.type === TypeTask.Stat) {
            props.addChannels(columns?.filter((x, y) =>
                v.index.includes(y))?.map((e) => e.ChannelFromFile ?? "") ?? [])

        } else {
            props.addChannels(columns?.filter((x, y) =>
                v.index.includes(y))?.map((e) => e.Chat.MainUsername) ?? [])
        }
        close();
    }

    return <div className="table-loading">
        <div className={"block-header"}>
            <TitleText text={"Результат"}/>
        </div>
        <DividerComponent/>
        {
            modal &&
            <TaskLoadingModalTable columns={columns}
                                   isLoading={loading}
                                   modal={modal}
                                   addArrayChannels={addArrayChannels}
                                   setColumns={setColumns}
                                   close={close}
                                   taskLoading={taskLoadingDto}/>
        }
        <div className={"result-items"}>
            {
                !TaskLoadingStore.taskLoadingList.hasModel &&
                <div className={"empty-loading-row"}>
                    <NewText2>Здесь будут отображены результаты ваших запросов</NewText2>
                </div>
            }
            {
                TaskLoadingStore.taskLoadingList.hasModel &&
                TaskLoadingStore.taskLoadingList.Model!.map(x =>
                    <TaskLoadingRowComponent key={x.id} model={x}
                                             repeatTask={repeatTask}
                                             open={open}/>)
            }
        </div>
    </div>
});