import {StateTask, TaskLoadingListDto} from "./models/TaskLoadingDto";
import {Card} from "@mantine/core";
import {
    IconAtOff, IconBan,
    IconCheck,
} from '@tabler/icons-react';
import './taskLoadingRowComponent.scss';
import {formatDateTimeWithEmpty} from "../helper/Formatting";
import {NewText2} from "../components/text/NewText2";
import {NewText} from "../components/text/NewText";
import {MouseEventHandler} from "react";

interface Props{
    model: TaskLoadingListDto,
    open: (id: bigint) => void,
    repeatTask: (id: bigint) => void
}

export const TaskLoadingRowComponent = (props: Props) => {
    const getTitle = () => {
        if (props.model.name)
            return props.model.name
        const json = JSON.parse(props.model.requestJson!);
        const channels = json['Channels'];
        const channelsString = channels.join(", ");
        return channelsString.length > 40 ? channelsString.substring(0, 40) + "..." : channelsString;
    }

    const getModelType = () => {
        switch (props.model.type) {
            case 1:
                return "Похожие каналы";
            case 2:
                return "Анализ каналов";
            case 3:
                return "Похожие каналы и последние сообщения";
            case 4:
                return "Анализ";
            case 5:
                return "Телеграм реклама";
            default:
                return "Дописать тип";
        }
    }

    const onOpen = () => {
        props.open(props.model.id);
    }

    const repeatTask = (event: MouseEventHandler<SVGSVGElement> | undefined) => {
        event?.stopPropagation();
        // event.
        props.repeatTask(props.model.id)
    }

    return (
        <Card className="task-loading-row cursorPointer" onClick={onOpen}>
            <div className={"header"}>
                <NewText className="header-title">{getTitle()}</NewText>
                <div className="header-status-icon">
                    {(props.model?.state === StateTask.Loading ||
                            props.model?.state === StateTask.NotStart) &&
                        <div className="loading-table-row">Выполняется...</div>
                    }
                    {
                        props.model?.state === StateTask.Finish &&
                        <IconCheck color="green" size={19}/>
                    }
                    {props.model?.state === StateTask.NotEnoughBalls &&
                        <IconAtOff color="red" size={19}/>
                    }
                    {props.model?.state === StateTask.Error &&
                        <IconBan color="red" size={19}/>
                    }
                    {
                        (props.model?.state === StateTask.Finish ||
                            props.model?.state === StateTask.NotEnoughBalls ||
                            props.model?.state === StateTask.Error) &&
                        <svg className="cursorPointer" onClick={repeatTask} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 16H5V21" stroke="#1BA2DE" strokeWidth="1.6" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path
                                d="M19.4176 14.9968C18.8569 16.3846 17.9182 17.5872 16.7081 18.468C15.498 19.3488 14.065 19.8725 12.5721 19.9795C11.0792 20.0865 9.58624 19.7726 8.26287 19.0734C6.9395 18.3742 5.83882 17.3177 5.08594 16.0241"
                                stroke="#1BA2DE" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M14 8H19V3" stroke="#1BA2DE" strokeWidth="1.6" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path
                                d="M4.58252 9.00315C5.1432 7.61541 6.08195 6.41278 7.29206 5.53197C8.50217 4.65116 9.93511 4.12749 11.428 4.02048C12.9209 3.91346 14.4139 4.2274 15.7373 4.92661C17.0606 5.62582 18.1613 6.68226 18.9142 7.97584"
                                stroke="#1BA2DE" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    }
                </div>
            </div>
            <div className={"wrapper-row"}>
                <div className={"type-calc"}>
                    <NewText2 className="text-left">Тип обработки</NewText2>
                    <NewText>{getModelType()}</NewText>
                </div>
                <div className={"date-calc"}>
                    <NewText2 className="text-left">Дата и время обработки</NewText2>
                    <NewText>{formatDateTimeWithEmpty((props.model?.finish?.toString() ?? undefined), '')}</NewText>
                </div>
            </div>
        </Card>
    );
}
