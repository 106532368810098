import React, {useEffect, useRef, useState} from "react";
import {FileInput} from "@mantine/core";

interface Props {
    label?: string;
    placeholder?: string;
    onChange?: (file: File | null) => void;
}

export const FileUpload = (props: Props) => {
    const ref = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        console.log('ref', ref.current?.value);
    }, [ref])
    const onchange = (payload: File | null) => {
        if (props.onChange){
            props.onChange(payload)
        }
    }
    return (
        <div>
            <FileInput onChange={onchange} ref={ref} label={props.label ?? "Загрузите файл"}
                       placeholder={props.placeholder ?? "Загрузите файл"}/>
        </div>
    );
};