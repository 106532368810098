import './block.scss';
import React from 'react';
import {Text} from "@mantine/core";
import {NewText2} from "../text/NewText2";

interface Props {
    sealed?: boolean
    title?: string
    description?: string
    onClick?: () => void;
}

export function Block(props: Props): JSX.Element {
    return <div className={"block " + (props.sealed ? "block-sealed" : "" )} onClick={props.onClick}>
        <Text className={"title"}>{props.title}</Text>
        <NewText2 text={props.description}/>
    </div>
}