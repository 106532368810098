import {Card} from "@mantine/core";
import {TitleText} from "../../components/titleText/TitleText";
import React from "react";
import AuthStore from "../store/AuthStore";
import {NewButton} from "../../components/button/NewButton";
import {NewText} from "../../components/text/NewText";


interface Props {
    email: string;
    clickAuth: () => void;
}

export const ConfirmEmailComponent = (props: Props) => {
    return <Card className="authorization-container auth-card" shadow="sm"
                 padding="lg" radius="md" withBorder>
        <TitleText className="text-block" text={"Проверьте почту"}/>
        <NewText className="marginTop32 marginBottom8">
            На вашу почту {props.email} отправлено письмо с инструкциями.
        </NewText>
        <NewText className="marginBottom32">
            На всякий случай проверьте папку «Спам».
        </NewText>
        <NewButton className="marginBottom16"
                   loading={AuthStore.isLoading}
                   text={"Отлично"}
                   onClick={props.clickAuth}
        />
    </Card>
}