import moment from "moment/moment";

export const splitChannel = '\n';

export const formatDate = (v: string): string => {
    if (v === undefined || v === null)
        return "Нету даты";
    let date = new Date(v);
    return date.toLocaleDateString();
}
export const formatDateTime = (v: string): string => {
    if (v === undefined)
        return "Нету даты";
    let d = new Date(v);
    return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." +
        d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
}
export const formatNewDateTime = (v: string): string => {
    if (v === undefined)
        return "Нету даты";
    // let d = new Date(v);
    return moment(v).format('D MMM HH:mm');
}
export const formatDateTimeWithEmpty = (v: string | undefined, emptyValue: string): string => {
    if (v === undefined)
        return emptyValue;
    return formatNewDateTime(v);
}
export const formatDateWithEmpty = (v: string | undefined, emptyValue: string): string => {
    if (v === undefined)
        return emptyValue;
    let date = new Date(v);
    return date.toTimeString();
}