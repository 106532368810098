import './newInput.scss';
import React from 'react';
import {PasswordInput, TextInput} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import {TextInputProps} from "@mantine/core/lib/components/TextInput/TextInput";
import {PasswordInputProps} from "@mantine/core/lib/components/PasswordInput/PasswordInput";

export enum InputType {
    input = 0,
    email = 2,
    password = 1
}

interface Props {
    className?: string;
    placeholder?: string;
    label?: string;
    error?: string
    type?: InputType
    inputProps?: TextInputProps
    inputPasswordProps?: PasswordInputProps
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
}

export function NewInput(props: Props): JSX.Element {
    const [visible, {toggle}] = useDisclosure(false);
    if (props.type === InputType.password) {
        return <PasswordInput
            placeholder={props.placeholder}
            visible={visible}
            onVisibilityChange={toggle}
            error={props.error}
            classNames={{input: 'new-input ' + props.className, error: 'new-input-error'}}
            {...props.inputPasswordProps}
        />
    }
    return <TextInput classNames={{input: 'new-input ' + props.className, error: 'new-input-error'}}
                      placeholder={props.placeholder}
                      value={props.value}
                      onChange={props.onChange}
                      error={props.error}
                      {...props.inputProps}
    />
}