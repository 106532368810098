import {Button} from "@mantine/core";
import './newButton.scss'

interface Props {
    text?: string;
    onClick?: () => void;
    disabled?: boolean;
    name?: string;
    className?: string;
    loading?: boolean;
}

export function NewButton(props: Props) {
    return <Button
        disabled={props.disabled}
        loading={props.loading}
        type="submit"
        className={props.disabled === true ? "new-button-dis " + props.className : "new-button " + props.className}
        onClick={props.onClick}>{props.text}</Button>
}