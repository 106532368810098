import React from "react";
import './forgotPasswordPage.scss'
import {Button, Input, PasswordInput, TextInput} from "@mantine/core";
import {hasLength, isEmail, useForm} from "@mantine/form";
import {postRequest} from "../../requests/HelperRequest";
import {AuthSignInModel, ForgotPasswordModel} from "../../auth/model/AuthModel";
import {LoadingModel} from "../../requests/LoadingModel";
import AuthStore from "../../auth/store/AuthStore";
import {NewButton} from "../../components/button/NewButton";
import {InputType, NewInput} from "../../components/inputs/NewInput";
import {TitleText} from "../../components/titleText/TitleText";


export const ForgotPasswordPage = () => {
    let passwordForm = useForm({
        initialValues: {
            newPassword: '',
            email: '',
            loading: false,
        },
        validate: {
            newPassword: hasLength({min: 8}, 'От 8 символов'),
            email: isEmail('Не проходит валидация по e-mail'),
        },
        initialErrors: {
            error: ""
        }
    });
    const setModel = (response: LoadingModel<AuthSignInModel>) => {
        passwordForm.setValues({
            loading: false,
        })
        if (response.Model?.successful) {
            alert('Вы изменили пароль к учетной записи. Пора авторизоваться.');
            AuthStore.redirect("/");
            return;
        }
        passwordForm.setErrors({error: response.Model?.errors});
    }
    const updatePassword = (newPassword: string, email: string) => {
        passwordForm.setValues({
            loading: true
        })
        postRequest<AuthSignInModel, ForgotPasswordModel>('api/Auth/ConfirmForgotPassword', {
            newPassword: newPassword,
            email: email,
            code: window.location.pathname.replace('/forgotPassword/', '')
        })
            .then((response) => {
                setModel(response);
            })
            .catch((error) => {
                alert('Что-то пошло не так. Попробуйте чуть позже!')
                console.log(error);
            });
    }

    return <div className="forgot-password-page">
        <div className="page">
            <form onSubmit={passwordForm.onSubmit((values, event) =>
                updatePassword(values.newPassword, values.email))}>

                <TitleText className="text-block marginBottom32" text={"Восстановить пароль"}/>

                <NewInput placeholder={"E-mail"}
                          className="marginTop16"
                          inputProps={passwordForm.getInputProps('email')}/>
                <NewInput type={InputType.password}
                          className="marginTop16"
                          placeholder={"Новый пароль"}
                          inputPasswordProps={passwordForm.getInputProps('newPassword')}
                />
                <div className="marginBottom32"></div>

                <NewButton
                    loading={passwordForm.values.loading}
                    text={"Изменить пароль"}/>
            </form>
        </div>
    </div>
}