import {observable} from "mobx";


export class LoadingModel<T>{
    @observable
    Model: T | null;
    @observable
    IsLoaded: boolean;
    @observable
    Error: string | null;
    @observable
    ErrorM?: ErrorModel;

    static createLoaded<T>():LoadingModel<T>{
        let res = new LoadingModel<T>();
        res.IsLoaded = true;
        return res;
    }
    static createNotLoaded<T>():LoadingModel<T>{
        return new LoadingModel<T>();
    }
    constructor() {
        this.IsLoaded = false;
        this.Model = null;
        this.Error = null;
    }
    public IsLoadedModel(): boolean{
        return this.IsLoaded;
    }
    public get hasModel(): boolean{
        if (this.Model && this.IsLoaded)
            return true;
        return false;
    }
    public setModel(model: T){
        this.Model = model;
        this.IsLoaded = true;
    }
    public setError(error: string){
        this.Error = error;
        this.IsLoaded = true;
    }
    public setErrorM(error: ErrorModel){
        this.Error = error.message;
        this.ErrorM = error;
        this.IsLoaded = true;
    }
}
interface ErrorModel{
    message: string;
}