import {Card, Text} from "@mantine/core";
import AuthStore from "../store/AuthStore";
import React, {useState} from "react";
import './newAuthorizationComponent.scss'
import {hasLength, useForm} from "@mantine/form";
import {TitleText} from "../../components/titleText/TitleText";
import {InputType, NewInput} from "../../components/inputs/NewInput";
import {NewButton} from "../../components/button/NewButton";
import {LinkText} from "../../components/text/LinkText";
import {ErrorText} from "../../components/text/ErrorText";


interface Props {
    clickRegistration: () => void;
    clickForgotPassword: () => void;
}

export const NewAuthorizationComponent = (props: Props) => {
    const formAuth = useForm({
        initialValues: {
            login: '',
            password: '',
        },
        validate: {
            login: hasLength({min: 6}, 'От 6 символов'),
            password: hasLength({min: 8}, 'От 8 символов')
        },
    });
    const [error, setError] = useState<string | null>();

    const auth = (loginEmail: string, password: string) => {
        AuthStore.authorization({
            loginEmail: loginEmail,
            password: password,
        }).then(x => {
            if (!x.successful){
                setError(x.error)
            }
        })
    }

    return <Card className="authorization-container auth-card" shadow="sm"
                 padding="lg" radius="md" withBorder>
        <form onSubmit={formAuth.onSubmit((values, event) =>
            auth(values.login, values.password))}>
            <TitleText className="text-block marginBottom32" text={"Войти"}/>

            <NewInput placeholder={"Логин или e-mail"}
                      inputProps={formAuth.getInputProps('login')}/>

            <NewInput type={InputType.password}
                      className="marginTop16"
                      placeholder={"Пароль"}
                      inputPasswordProps={formAuth.getInputProps('password')}
            />
            <div className="marginBottom8"></div>
            {error && <ErrorText>{error}</ErrorText>}
            <div className="marginBottom8"></div>
            <NewButton className="marginBottom16 marginTop16"
                       loading={AuthStore.isLoading}// todo Это не работает!
                       text={"Войти"}
            />

            <div className="marginTop16">
                <div className="wrapper-auth-remember">
                    <LinkText onClick={props.clickForgotPassword} className="forgot-password">Забыли пароль?</LinkText>
                </div>
                <div className="wrapper-auth-registration">
                    <Text className="text text-not-account">нет аккаунта?</Text>
                    <LinkText onClick={props.clickRegistration}>зарегистрируйтесь</LinkText>
                </div>
            </div>
        </form>
    </Card>
}