import {Text} from "@mantine/core";
import React from "react";
import './linkText.scss'

interface Props {
    className?: string;
    children?: React.ReactNode;
    onClick?: () => void;
}

export const LinkText = (props: Props) =>{
    return <Text onClick={props.onClick} className={"link-text " + props.className}>{props.children}</Text>
}