import {Text} from "@mantine/core";
import React from "react";
import './errorText.scss'

interface Props {
    className?: string;
    children?: React.ReactNode;
    onClick?: () => void;
}

export const ErrorText = (props: Props) =>{
    return <Text onClick={props.onClick} className={"error-message " + props.className}>{props.children}</Text>
}