import {observer} from "mobx-react";
import React, {ChangeEvent, Dispatch, SetStateAction, useState} from "react";
import {TaskLoadingCreateDto, TypeTask} from "./models/TaskLoadingDto";
import TaskLoadingStore from "./TaskLoadingStore";
import {splitChannel} from "../helper/Formatting";
import {Tooltip} from "@mantine/core";
import {TitleText} from "../components/titleText/TitleText";
import './combinedComponent.scss';
import './taskLoadingCreateComponent.scss';
import {NewInput} from "../components/inputs/NewInput";
import {NewText} from "../components/text/NewText";
import {NewTextArea} from "../components/inputs/NewTextArea";
import {Block} from "../components/block/Block";
import {NewButton} from "../components/button/NewButton";
import {DividerComponent} from "../components/divider/DividerComponent";

interface Props {
    channel: string,
    setChannel: Dispatch<SetStateAction<string>>,
}

export const TaskLoadingCreateComponent = observer((props: Props) => {
    let [taskName, setTaskName] = useState<string>('');
    let [errorMessageChannel, setErrorMessageChannel] = useState('');
    let [typeProcessing, setTypeProcessing] = useState<TypeTask>(TypeTask.Similar);


    const requestCreate = (model: TaskLoadingCreateDto) => {
        setErrorMessageChannel('');
        if (props.channel === '' || listChannels().length === 0) {
            setErrorMessageChannel('Заполните список каналов');
            return;
        }
        const modelRequest: TaskLoadingCreateDto = {
            ...model,
            name: taskName,
        };
        TaskLoadingStore.createRequest(modelRequest)
            .then(x => {
                if (x.status === 401) {
                    x.json().then(e => alert(e.message));
                }
                if (x.status === 200) {
                    props.setChannel('');
                    setTaskName('');
                }
            });
    };

    const createRequest = () => {
        setErrorMessageChannel('');
        if (typeProcessing === TypeTask.Similar) {
            if (listChannels().length > 200) {
                setErrorMessageChannel('Можно не больше 200 каналов.');
                return;
            }
        }
        const model: TaskLoadingCreateDto = {
            name: taskName,
            channels: listChannels(),
            type: typeProcessing,
        };
        requestCreate(model);
    }

    const clear = () => {
        setTaskName('');
        props.setChannel('');
    }

    const handleFileUpload = (ev: ChangeEvent<HTMLInputElement>) => {
        const file = ev.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const text = e.target?.result;
                if (text) {
                    props.setChannel(text as string);
                }
            };
            reader.readAsText(file);
        }
    };

    function listChannels() {
        return props.channel.split(splitChannel).filter(x => x !== '');
    }

    const analyzeClick = () => {
        setTypeProcessing(TypeTask.Stat);
    }
    const adsClick = () => {
        setTypeProcessing(TypeTask.AdsTelegram);
    }
    const similarClick = () => {
        setTypeProcessing(TypeTask.Similar);
    }

    const changeTaskName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaskName(e.target.value);
    }

    const changeChannel = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setErrorMessageChannel('');
        props.setChannel(e.target.value);
    }

    return (
        <div className="create-loading">
            <div className={"block-header"}>
                <TitleText text={"Запрос"}/>
            </div>
            <DividerComponent/>
            <div className="textarea-container">
                <NewText className={"marginTop20"}>Наименование запроса</NewText>
                <NewInput value={taskName}
                          onChange={changeTaskName}
                          className={"marginTop16"}
                          placeholder={"Начните вводить"}/>

                <NewText className={"marginTop20 marginBottom16"}>Список каналов</NewText>
                <NewTextArea
                    value={props.channel}
                    onChange={changeChannel}
                    error={errorMessageChannel}
                    placeholder="Начните вводить каналы, каждый канал с новой строчки. Не пишите закрытые каналы, так как Telegram позволяет находить похожие каналы только у открытых каналов."
                />
                <div className="file-upload-container">
                    <input
                        type="file"
                        id="file-upload"
                        className="file-upload"
                        accept=".txt"
                        onChange={handleFileUpload}
                    />
                    <label htmlFor="file-upload" className="file-upload-label">
                        <Tooltip label="Загрузить файл">
                            <svg width="19" height="20" viewBox="0 0 19 20"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.53564 9.46445L8.42994 2.57016C10.4802 0.519908 13.8043 0.519907 15.8546 2.57016C17.9048 4.62041 17.9048 7.94453 15.8546 9.99478L7.89961 17.9497C6.53277 19.3166 4.31669 19.3166 2.94986 17.9497C1.58302 16.5829 1.58302 14.3668 2.94986 13L10.9048 5.04503C11.5882 4.36162 12.6963 4.36162 13.3797 5.04503C14.0631 5.72845 14.0631 6.83649 13.3797 7.51991L6.48539 14.4142"
                                    stroke="#737880" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </Tooltip>
                    </label>
                </div>
            </div>
            <NewText className={"marginTop20 marginBottom16"}>Тип обработки</NewText>

            <div className={"wrapper-blocks"}>
                <Block title={"Анализировать"}
                       sealed={typeProcessing === TypeTask.Stat}
                       onClick={analyzeClick}
                       description={"Собирает информацию по каждому из указанных каналов."}/>
                <Block title={"Найти похожие каналы"}
                       sealed={typeProcessing === TypeTask.Similar}
                       onClick={similarClick}
                       description={"Подбирает каналы, схожие с указанными, и формирует список с частотой их упоминаний."}/>
                <Block title={"Найти посты из Telegram Ads"}
                       sealed={typeProcessing === TypeTask.AdsTelegram}
                       onClick={adsClick}
                       description={"Ищет рекламные посты по заданным ключевым словам."}/>
            </div>
            <div className={"wrapper-buttons"}>
                <NewButton onClick={createRequest} text={"Обработать"} className={"calc_button"}/>
                <NewButton onClick={clear} text={"Сбросить"} className={"cancel_button"}/>
            </div>
        </div>
    );
});