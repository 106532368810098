import {CSSProperties} from "react";

interface Props {
    className?: string;
}

// export const CustomUserImage = (props: Props) => {
//     return <svg className={props.className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <rect width="40" height="40" rx="10" fill="#F3F4F7"/>
//         <path
//             d="M20.0016 12.5337C19.0114 12.5337 18.0618 12.927 17.3617 13.6272C16.6616 14.3273 16.2682 15.2769 16.2682 16.267C16.2682 17.2572 16.6616 18.2068 17.3617 18.9069C18.0618 19.607 19.0114 20.0004 20.0016 20.0004C20.9917 20.0004 21.9413 19.607 22.6414 18.9069C23.3416 18.2068 23.7349 17.2572 23.7349 16.267C23.7349 15.2769 23.3416 14.3273 22.6414 13.6272C21.9413 12.927 20.9917 12.5337 20.0016 12.5337ZM14.4016 16.267C14.4016 14.7818 14.9916 13.3574 16.0418 12.3072C17.092 11.257 18.5163 10.667 20.0016 10.667C21.4868 10.667 22.9112 11.257 23.9614 12.3072C25.0116 13.3574 25.6016 14.7818 25.6016 16.267C25.6016 17.7523 25.0116 19.1766 23.9614 20.2268C22.9112 21.277 21.4868 21.867 20.0016 21.867C18.5163 21.867 17.092 21.277 16.0418 20.2268C14.9916 19.1766 14.4016 17.7523 14.4016 16.267ZM16.2682 25.6004C15.5256 25.6004 14.8134 25.8954 14.2883 26.4205C13.7632 26.9456 13.4682 27.6578 13.4682 28.4004C13.4682 28.6479 13.3699 28.8853 13.1949 29.0603C13.0198 29.2354 12.7824 29.3337 12.5349 29.3337C12.2874 29.3337 12.05 29.2354 11.8749 29.0603C11.6999 28.8853 11.6016 28.6479 11.6016 28.4004C11.6016 27.1627 12.0932 25.9757 12.9684 25.1005C13.8436 24.2254 15.0306 23.7337 16.2682 23.7337H23.7349C24.9726 23.7337 26.1596 24.2254 27.0347 25.1005C27.9099 25.9757 28.4016 27.1627 28.4016 28.4004C28.4016 28.6479 28.3032 28.8853 28.1282 29.0603C27.9532 29.2354 27.7158 29.3337 27.4682 29.3337C27.2207 29.3337 26.9833 29.2354 26.8083 29.0603C26.6332 28.8853 26.5349 28.6479 26.5349 28.4004C26.5349 27.6578 26.2399 26.9456 25.7148 26.4205C25.1897 25.8954 24.4775 25.6004 23.7349 25.6004H16.2682Z"
//             fill="#1B2E45"/>
//     </svg>
// }
let css: CSSProperties = {
    backgroundImage: 'url(./userImage.png)',
    width: '40px', height: '40px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
}
export const CustomUserImage = (props: Props) => {
    return <div className={"customUserImage " + props.className} style={css}></div>
}
let css2: CSSProperties = {
    backgroundImage: 'url(./payment.png)',
    width: '40px', height: '40px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
}
export const CustomPaymentImage = (props: Props) => {
    return <div className={"customPaymentImage " + props.className} style={css2}></div>
}