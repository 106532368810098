export interface PromoCodeListDto {
    id: bigint;
    code: string;
    percent: number
    cost: number
    promoCodeDiscountType: TypePromoCodeDiscount
    maxCountActive: number
    start: string
    finish: string
    createObject: string
}

export enum TypePromoCodeDiscount {
    Percent = 1,
    Cost = 2,
}

export interface PromoCodeCreateDto {
    code: string;
    percent?: number
    cost?: number
    promoCodeDiscountType: TypePromoCodeDiscount
    maxCountActive?: number
    start?: Date
    finish?: Date
}

export interface PromoCodeRequest {
    lastId?: bigint
}
