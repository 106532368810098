import {getRequest, postRequest, postRequestRequest} from "../requests/HelperRequest";
import AuthStore from "../auth/store/AuthStore";
import {PromoCodeCreateDto, PromoCodeListDto, PromoCodeRequest} from "./models/PromoCodeListDto";

export class PromoCodeRepository {
    loaded = true;

    create(dto: PromoCodeCreateDto) {
        this.loaded = false;
        return postRequestRequest<PromoCodeCreateDto>('api/PromoCode/create', dto)
            .catch((error) => {
                // alert('Возникла ошибка напиши в подержку!')
                console.log(error);
            }).finally(() => {
            this.loaded = true;
        });
    }

    list(lastId: bigint | undefined) {
        this.loaded = false;
        return getRequest<PromoCodeListDto[]>('api/PromoCode/List')
            .catch((error) => {
                // alert('Возникла ошибка напиши в подержку!')
                console.log(error);
            }).finally(() => {
                this.loaded = true;
            });
    }
}