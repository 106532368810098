import React, {useEffect, useState} from 'react';
import './promocodePage.scss';
import {Loading} from "../shared/Loading";
import {Button, Card, Group, Input, Modal, NumberInput, Radio, Textarea, TextInput} from '@mantine/core';
import {PromoCodeRepository} from "./PromoCodeRepository";
import {PromoCodeListDto, TypePromoCodeDiscount} from "./models/PromoCodeListDto";
import {useForm} from "@mantine/form";
import {StateTask} from "../taskLoading/models/TaskLoadingDto";
import {IconAtOff, IconBan, IconCheck, IconLoader, IconRefresh} from "@tabler/icons-react";
import {formatDateTime} from "../helper/Formatting";


export const PromoCodePage = () => {
    let [dtos, setDtos] = useState<PromoCodeListDto[] | undefined>(undefined);
    let formCreatePromoCode = useForm({
        initialValues: {
            // start?: Date
            // finish?: Date

            code: '',
            percent: 0,
            cost: 0,
            promoCodeDiscountType: TypePromoCodeDiscount.Cost,
            maxCountActive: 0,
            loading: false,
            opened: false,
        },
    });
    let repository = new PromoCodeRepository();
    const request = () => {
        setDtos(undefined);
        repository.list(undefined)
            .then(x => {
                if (x){
                    if (!!x.Model) {
                        setDtos(x.Model!)
                    } else if (!!x.ErrorM) {
                        alert(x.Error)
                    }
                }
            })
    }

    useEffect(() => {
        request();
    }, [])

    const openCloseModal = () =>{
        formCreatePromoCode.setValues({opened: !formCreatePromoCode.values.opened})
    }


    return (
        <div className="promocode-page">
            <div className="history">
                <h1>Промокоды</h1>

                <Button
                    className="save-button"
                    variant="gradient"
                    onClick={openCloseModal}
                    gradient={{from: 'blue', to: 'cyan', deg: 90}}>
                    Создать промокод
                </Button>

                <Modal opened={formCreatePromoCode.values.opened} size={1300} onClose={openCloseModal} title="Создать промокод">
                    <form
                        onSubmit={formCreatePromoCode.onSubmit((values, event) =>
                        repository.create({
                            code: values.code,
                            cost: values.cost,
                            maxCountActive: values.maxCountActive,
                            percent: values.percent,
                            promoCodeDiscountType: values.promoCodeDiscountType
                        }).then(x => request()))}
                    >
                        <Input.Wrapper label="Промокод код">
                            <TextInput placeholder="Введите Промокод" {...formCreatePromoCode.getInputProps('code')}/>
                        </Input.Wrapper>
                        <Group>
                            <Radio checked={false} label="Проценты" value="nu" />
                            <Radio checked label="Определенная цена" value="sv" />
                        </Group>
                        <Input.Wrapper label="Введите процент">
                            <NumberInput disabled={formCreatePromoCode.values.promoCodeDiscountType === TypePromoCodeDiscount.Cost} mt="sm" placeholder="Введите процент" {...formCreatePromoCode.getInputProps('percent')}/>
                        </Input.Wrapper>
                        <Input.Wrapper label="Введите цену">
                            <NumberInput disabled={formCreatePromoCode.values.promoCodeDiscountType === TypePromoCodeDiscount.Percent} mt="sm" placeholder="Введите цену" {...formCreatePromoCode.getInputProps('cost')}/>
                        </Input.Wrapper>
                        <Button
                            className="save-button"
                            variant="gradient"
                            type="submit"
                            loading={formCreatePromoCode.values.loading}
                            gradient={{from: 'blue', to: 'cyan', deg: 90}}>
                            Добавить
                        </Button>
                    </form>
                </Modal>

                {!dtos && <Loading/>}
                {dtos?.map((x, index) => (
                    <Card shadow="sm" padding="lg" radius="md" withBorder className="page" key={x.id}>
                        <Card.Section>
                            <Group className="header-body">
                                <div className="title" >Промокод: <span className="title_span" title={x.code}>{x.code}</span></div>
                            </Group>
                            <Group className="header-body">
                                <div className="title" >Стоимость скидки: <span className="title_span">{x.cost}</span></div>
                            </Group>
                            <Group className="header-body">
                                <div className="title" >Дата создания: <span className="title_span">{formatDateTime(x.createObject)}</span></div>
                            </Group>
                        </Card.Section>
                        {/*<div>*/}
                        {/*    {x.balls > 0 && <div>Добавили баллов: {x.balls}</div>}*/}
                        {/*    {x.balls <= 0 && <div>Потратили баллов: {-x.balls}</div>}*/}
                        {/*</div>*/}
                        {/*<div>Дата: {formatDateTime(x.dateWriteOff)}</div>*/}
                    </Card>
                ))}
            </div>
        </div>
    );
};
