import './newText.scss';
import React from 'react';
import {Text} from "@mantine/core";

interface Props {
    // text?: string;
    className?: string;
    onClick?: () => void;
    children?: React.ReactNode;
}

export function NewText(props: Props): JSX.Element {
    return <Text className={"new-text " + props.className} onClick={props.onClick}>
        {props.children}
    </Text>
}