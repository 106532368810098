import React, {useState} from 'react';
import './combinedComponent.scss';
import {splitChannel} from '../helper/Formatting';
import {TaskLoadingTableComponent} from "./TaskLoadingTableComponent";
import {TaskLoadingCreateComponent} from "./TaskLoadingCreateComponent";

export const CombinedComponent = () => {
    let [channel, setChannel] = useState('');

    const addChannels = (channels: string[]) => {
        if (channels.length === 0) return;
        if (channel === "") {
            setChannel(channels.join(splitChannel));
            return;
        }
        let oldChannels = channel.split(splitChannel).filter((x) => x !== '');
        let filteredChannels = channels.filter((x) => !oldChannels.includes(x));
        setChannel(prevState => prevState + splitChannel + filteredChannels.join(splitChannel));
    };

    return (
        <>
            <TaskLoadingCreateComponent channel={channel} setChannel={setChannel}/>
            <TaskLoadingTableComponent addChannels={addChannels}/>
        </>
    );
};
