import {LoadingModel} from "../../requests/LoadingModel";
import {makeAutoObservable} from "mobx";
import {AccountTelegramModel} from "./models/AccountTelegramModel";
import {getRequest} from "../../requests/HelperRequest";


class AccountTelegramStore {
    list = LoadingModel.createLoaded<AccountTelegramModel[]>();

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
    }

    taskLoadingListSet(model: LoadingModel<AccountTelegramModel[]>) {
        this.list = model;
    }

    request = (): void => {
        getRequest<AccountTelegramModel[]>('api/TelegramAccount/List')
            .then((response) => {
                this.taskLoadingListSet(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    authorizationRequest = (id: bigint): void => {
        getRequest<object>('api/TelegramAccount/Authorization/' + id)
            .then((response) => {
                this.request();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    deleteRequest = (id: bigint): void => {
        getRequest<object>('api/TelegramAccount/delete/' + id)
            .then((response) => {
                this.request();
            })
            .catch((error) => {
                console.log(error);
            });
    }


}

export default new AccountTelegramStore();