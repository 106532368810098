import './titleText.scss';
import React from 'react';
import {Text} from "@mantine/core";

interface Props {
    className?: string;
    text: string;
}

export function TitleText(props: Props): JSX.Element {
    return <Text className={"title-text " + props.className}>{props.text}</Text>
}