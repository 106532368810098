import React from "react";
import './paymentHistoryComponent.scss';
import {NewText} from "../text/NewText";
import {NewText2} from "../text/NewText2";
import {PaymentsHistoriesModel} from "../../balls/models/PaymentsHistoriesModel";
import {formatNewDateTime} from "../../helper/Formatting";

interface Props{
    model: PaymentsHistoriesModel
}

export const PaymentHistoryComponent = (props: Props) => {
    return <div className="payment-history-component">
        <div className="payment-history-wrapper">
            <NewText2 text={"баллы"} className={"text-title"}/>
            <NewText className={"text-description"}>{props.model.balls}</NewText>
        </div>
        <div className="payment-history-wrapper">
            <NewText2 text={"дата и время обработки"} className={"text-title"}/>
            <NewText className={"text-description"}>{formatNewDateTime(props.model.dateWriteOff)}{/*27 сен 18:32*/}</NewText>
        </div>
    </div>
}