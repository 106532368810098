import {Card} from "@mantine/core";
import AuthStore from "../store/AuthStore";
import React, {useState} from "react";
import {hasLength, isEmail, useForm} from "@mantine/form";
import {TitleText} from "../../components/titleText/TitleText";
import {InputType, NewInput} from "../../components/inputs/NewInput";
import {NewButton} from "../../components/button/NewButton";
import {LinkText} from "../../components/text/LinkText";
import {ErrorText} from "../../components/text/ErrorText";
import {getErrors} from "../../helper/Errors";

interface Props {
    clickAuth: () => void;
    confirmEmail: (email: string) => void;
}

export const NewRegistrationComponent = (props: Props) => {
    const [error, setError] = useState<string | null>();
    const formRegistration = useForm({
        initialValues: {
            login: '',
            email: '',
            firstname: '',
            surname: '',
            password: '',
        },
        validate: {
            login: hasLength({min: 2, max: 30}, 'От 2 символов до 30 символов'),
            firstname: hasLength({min: 2, max: 30}, 'От 2 символов до 30 символов'),
            surname: hasLength({min: 2, max: 30}, 'От 2 символов до 30 символов'),
            email: isEmail('Не проходит валидация по e-mail'),
            password: hasLength({min: 8}, 'От 8 символов')
        },
    });



    const registrationSubmit = (email: string, firstname: string, login: string, password: string, surname: string,) => {
        AuthStore.registration({
            email: email, firstname: firstname, login: login, password: password, surname: surname
        }).then(x => {
            if (x.successful){
                props.confirmEmail(email);
            }
            if (!x.successful){
                setError(x.error)
            }
        });
    };

    return <Card className="auth-card registration-container" shadow="sm" padding="lg" radius="md" withBorder>
        <form onSubmit={formRegistration.onSubmit((values, event) =>
            registrationSubmit(values.email, values.firstname, values.login, values.password, values.surname))}>
            <TitleText className="text-block"  text={"Регистрация"}/>
            <NewInput className="marginTop16"
                      placeholder={"Логин"}
                      inputProps={formRegistration.getInputProps('login')}/>
            <NewInput className="marginTop16"
                      placeholder={"E-mail"}
                      inputProps={formRegistration.getInputProps('email')}/>
            <NewInput className="marginTop16"
                      placeholder={"Имя"}
                      inputProps={formRegistration.getInputProps('firstname')}/>
            <NewInput className="marginTop16"
                      placeholder={"Фамилия"}
                      inputProps={formRegistration.getInputProps('surname')}/>
            <NewInput className="marginTop16 marginBottom16"
                      placeholder={"Пароль"}
                      type={InputType.password}
                      inputPasswordProps={formRegistration.getInputProps('password')}/>
            <ErrorText className="">{getErrors(error)}</ErrorText>
            <NewButton className="marginTop16"
                       loading={AuthStore.isAuth}// todo Это не работает!
                       text={"Регистрация"}
            />
            <LinkText onClick={props.clickAuth} className="marginTop16">Уже зарегистрированы?</LinkText>
        </form>
    </Card>
}