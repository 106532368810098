import {observer} from "mobx-react";
import React, {ChangeEvent, useEffect, useState} from "react";
import {Loading} from "../../shared/Loading";
import AccountTelegramStore from "./AccountTelegramStore";
import {ActionIcon, Button, Card, Group, Menu, Modal, rem, Textarea} from "@mantine/core";
import {IconDots, IconHttpDelete} from "@tabler/icons-react";
import './accountTelegramComponent.scss'
import {FileUpload} from "../../components/FileUpload";
import {postFormRequest} from "../../requests/HelperRequest";

interface Props {

}

export const AccountTelegramsPage = observer((props: Props) => {
    let [modal, setModal] = useState(false);
    let [textArea, setTextArea] = useState<string>();
    let [fileSession, setFileSession] = useState<File | null>();

    useEffect(() => {
        AccountTelegramStore.request();
    }, [])

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         AccountTelegramStore.request();
    //     }, 2000);
    //     // очистка интервала
    //     return () => clearInterval(timer);
    // }, []);

    const close = () => {
        setModal(false)
    }
    const open = () => {
        setModal(true)
    }

    const deleteAccount = (id: bigint) => {
        AccountTelegramStore.deleteRequest(id);
    }

    const checkAuthorization = (id: bigint) => {
        AccountTelegramStore.authorizationRequest(id);
    }

    const changeTextArea = (ev: ChangeEvent<HTMLTextAreaElement>) => {
        setTextArea(ev.currentTarget.value);
    }

    const updateJson = (file: File | null) => {
        if (!file?.name.includes(".json")){
            alert('Нужен файл формата JSON')
            return;
        }
        file?.text().then((val) => {
            setTextArea(val);
        }).catch(reason => {
            console.error('reason ', reason);
        })
    }

    const updateSession = (file: File | null) => {
        setFileSession(file)
    }

    const addNewAccount = () =>{
        const data = new FormData()
        data.set("session", fileSession);
        data.set("JsonClient", textArea);
        postFormRequest("api/TelegramAccount/create", data)
            .then(x => {
                AccountTelegramStore.request();
            })
    }

    const basicStatus = (key: number) =>{
        if (key === 0)
            return "Пустой"
        if (key === 1)
            return "Инициализация"
        if (key === 2)
            return "Проверка кода"
        if (key === 3)
            return "Проверка пароля"
        if (key === 4)
            return "Валидация авторизация"
        if (key === 100)
            return "Успешно авторизован"
        if (key === 500)
            return "Ошибка"
        throw Error("Ошибка кода: " + key);
    }
    const additionalStatus = (key: number) =>{
        if (key === 0)
            return "Пустой"
        if (key === 1)
            return "Ждем ответа от вас"
        if (key === 2)
            return "Ждем ответа от Сервера"
        if (key === 500)
            return "Ошибка"
        if (key === 100)
            return "Успешно"
        throw Error("Ошибка второго кода: " + key);
    }


    return <div className="account-telegram-page">
        <div className="account-telegram-component">
            <h3>Список аккаунтов</h3>
            <Modal opened={modal} size={1300} onClose={close} title="Добавить аккаунт">
                <Textarea
                    label="Input label"
                    placeholder="JSON "
                    rows={20}
                    value={textArea}
                    onChange={changeTextArea}
                />
                <FileUpload onChange={updateJson} label="Загруте JSON файл" placeholder="Загруте JSON файл"/>
                <FileUpload onChange={updateSession} label="Загруте session" placeholder="Загруте session"/>
                <Group justify="flex-end" mt="md">
                    <Button
                        variant="gradient"
                        type="submit"
                        onClick={addNewAccount}
                        gradient={{from: 'blue', to: 'cyan', deg: 90}}>
                        Добавить аккаунт
                    </Button>
                </Group>
            </Modal>
            <Button onClick={open} gradient={{from: 'blue', to: 'cyan', deg: 90}}>Добавить аккаунт</Button>
            {
                !AccountTelegramStore.list.hasModel &&
                <Loading/>
            }
            {
                AccountTelegramStore.list.hasModel &&
                AccountTelegramStore.list.Model!.map(x =>
                    <Card key={x.id} className="task-loading-row" shadow="sm" padding="lg" radius="md" withBorder>
                        <Card.Section>
                            <Group className="header-body">
                                <div className="title">Аккаунт: <span className="title_span"
                                                                      title={x.phoneNumber}>{x.phoneNumber}</span></div>
                                <div>
                                    <Menu withinPortal position="bottom-end" shadow="sm">
                                        <Button onClick={() => checkAuthorization(x.id)}>Авторизация</Button>
                                        <Menu.Target>
                                            <ActionIcon variant="subtle" color="gray">
                                                <IconDots style={{width: rem(16), height: rem(16)}}/>
                                            </ActionIcon>
                                        </Menu.Target>

                                        <Menu.Dropdown>
                                            <Menu.Item onClick={() => deleteAccount(x.id)}
                                                       leftSection={<IconHttpDelete
                                                           style={{width: rem(14), height: rem(14)}}/>}>
                                                Удалить
                                            </Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                </div>
                            </Group>
                        </Card.Section>
                        <div className="task-loading-component">
                            <div>Дата последней авторизации: <span>{x.lastDateAuth}</span></div>
                            <div>Начало авторизации: <span>{x.startPeriod}</span></div>
                            <div>Статус: <span>{basicStatus(x.basicStatus)}</span></div>
                            <div>Дополнительный статус: <span>{additionalStatus(x.additionalStatus)}</span></div>
                        </div>
                    </Card>)
            }
        </div>
    </div>
})